
import Vue from "vue"
import { mapGetters } from "vuex"
import { findBlockByAttribute } from "~/utils/block"
import { COMPARISON_TABLE_ITEM, RESPONSIVE_IMAGE_SET } from "~/constants/blocks"
import { processBlock } from "~/scripts/useful-functions"
import { GET_BOOKINGS_COPY, GET_POSTAL_CODE } from "~/store/booking-insights/getters"

export default Vue.extend({
  name: "MediaTextResponsive",

  props: {
    block: {
      required: true,
      type: Object as () => Block
    },
    dynamicContent: {
      type: Boolean
    },
    lazy: {
      default: true,
      type: Boolean
    }
  },

  data() {
    return {
      replacementData: {} as Dictionary,
      blockToUse: {} as Block
    }
  },

  computed: {
    parentElementName() {
      // Because MediaTextResponsive is used several times on one page, hydration errors can occur when for-loop keys are reused.
      // A nice solution for this and for editors it to give the parent Group block a name that's unique on that page.
      const groupNameAttribute: string = (
        this.block.attributes.find(attr => attr.name === "metadata") || {
          value: '{\\"name\\":\\"unnamed-group\\"}'
        }
      ).value
      return JSON.parse(groupNameAttribute).name.replace(/\s+/g, "")
    },
    desktopImageAlignedRight() {
      const SUPPORTED_BLOCKS = [RESPONSIVE_IMAGE_SET, COMPARISON_TABLE_ITEM]

      return SUPPORTED_BLOCKS.some(className => {
        const matchingBlock = findBlockByAttribute(this.block, "className", className)
        return matchingBlock && findBlockByAttribute(matchingBlock, "align", "right")
      })
    },
    ...mapGetters("booking-insights", {
      postalCode: GET_POSTAL_CODE,
      headerCopy: GET_BOOKINGS_COPY
    })
  },

  watch: {
    headerCopy: {
      immediate: true,
      handler(newValue: AvBookingsCopy) {
        if (newValue && this.dynamicContent) {
          const {
            cheeky_title,
            average_home_move_cost,
            average_four_bed_home_move_cost,
            average_number_of_moves_by_agent,
            location,
            short_postcode
          } = newValue
          this.replacementData = {
            ...this.replacementData,
            cheeky_title,
            average_home_move_cost,
            average_four_bed_home_move_cost,
            average_number_of_moves_by_agent,
            location,
            short_postcode
          }
          this.replaceDynamicContent()
        }
      }
    }
  },

  created() {
    if (this.dynamicContent) return
    this.blockToUse = { ...this.block }
  },

  methods: {
    replaceDynamicContent() {
      this.$nextTick(() => {
        this.blockToUse = processBlock(this.block, this.replacementData)
      })
    }
  }
})
